export const ApiConfig = {
  auth: {
    login: "auth/login",
    resetPassword: "auth/reset-password",
    forgotPassword: "auth/forgot-password",
    register: "auth/player/register",
    verifyEmail: "auth/verify-account",
    logout: "auth/logout",
  },
  dashboard: {
    info: '/dashboard',
    stats: '/dashboard/player-stats',
    logs: '/dashboard/game-logs'
  },
  profile: {
    load: "profile",
    update: "profile/update",
    updatePic: 'profile/update-profile-pic',
    updateProfilePicture: 'profile/updateProfilePicture',
    changePassword: "profile/change-password",
  },
  player: {
    teams: 'player/teams',
    fetchtrades:"player/teams/fetchtrades",
    fetchtradeshistory:"player/teams/fetchtradeshistory",
    updatetrade:"player/teams/updatetrade",
    listwithpagination:'player/teams/listwithpagination',
    updateteamwithids: 'player/teams/updateteamwithids',
    getteamwithids: 'player/teams/fetchteamswithids',
    checkRegisterStatus:"player/teams/check-register-status",
    getDraftStatus:"player/teams/get-draft-status",
    checkPlayerInDraft:'player/teams/check-player-draft-status',
    leagues: 'leagues',
    playerSearch: 'player/teams/player-search',
    addPlayer: 'player/teams/team_id/add-player',
    addPlayerse: 'player/teams/team_id/add-playerse',
    updatePlayer: 'player/teams/team_id/update-player',
    removePlayer: 'player/teams/team_id/remove-player',
    orderupdate: 'player/teams/orderupdate',
    invites: 'player/invites',
    updateteamlogo: '/player/updateprofilelogo',
    registrations: {
      registered: 'player/registrations',
      registeredWithConfirm: 'player/registrations/register-confirm',
      upcoming: 'player/registrations/upcoming'
    }
  },
  courts: "/courts",
  matches: {
    updatestatus:"matches/updatestatus",
    payablematches:"matches/payablematches",
    getPlayerName:"matches/getPlayerName",
    updatePayments:"matches/updatePayments",
    updatepaymonth:"matches/updatepaymonth",
    paysummary:"matches/paysummary",
    getManagers:"/users/getManagers",
    paymentDetails:'matches/paymentDetails',
    unassignmatch: 'matches/unassignmatch',
    updatePayment: 'matches/updatePayment',
    getplayermatchlogs: 'matches/getplayermatchlogs',
    list: 'matches',
    resetStats: 'matches/reset-team-stats',
    newlist: 'matches/newlist',
    getnewmanagermatches: 'matches/getnewmanagermatches',
    matchLogs: 'matches/logs',
    quickMatchCreateDetails: 'matches/quick-match-details',
    updateScorer: 'matches/update-scorer',
    updateScore: 'matches/updatescore',
    updateScorerStatus: 'matches/update-scorer-status',
    updateScorerManager: 'matches/update-scorer-manager',
    updateDetails: 'matches/update-details',
    updateDetailsManager: 'matches/update-details-manager',
    updateMatch: 'matches/update-match',
    updateBulkSwitch: 'matches/updateBulkSwitch',
    updateMatchActive:"matches/update-match-active",
    updateMatchManager: 'matches/update-match-manager',
    updateScheduledMatch: 'matches/update-scheduled-match',
    updateScheduledMatchNew: 'matches/update-scheduled-matchnew',
    startMatch: 'matches/start-match',
    startMatchNew: 'matches/start-match-new',
    startMatchManager: 'matches/start-match-manager',
    jerseyViolationManager: 'matches/jersey-violation-manager',
    addPlayers: 'matches/add-players',
    teamstats: 'matches/teamstats',
    addPlayerToTeam: 'matches/add-player-to-team',
    updateMatchScore: 'matches/update-score',
    updateMatchScoreManager: 'matches/update-score-manager',
    getScorer: 'matches/scorer',
    getScorermanager: 'matches/scorermanager',
    downloadSampleFile: 'matches/game-creation-file',
    lastUploadHistory: 'matches/last-upload-history',
    updatednp: 'matches/updatednp',
    getTotalPublish: 'matches/totalPublishedMatches',
    getTotalPublishseason: 'matches/totalPublishedMatchesseason',
    getfiltersteam: 'matches/getFiltersteam',
    createBulkMatches: 'matches/createbulkmatches',
    getmatchesdate: 'matches/getmatchesdate',
    sendMails: 'matches/send-mails',
  },
  users: '/users',
  userInfo: {
    count: 'users/count'
  },
  leagues: '/leagues',
  seasons: '/seasons',
  season: {
    updateTierForRegisteredTeam: '/seasons/update-registerd-team-tier',
    removeRegisteredTeamFromTier: '/seasons/remove-registerd-team-tier',
    archiveseason: '/seasons/archive',
    toggleEnableRegistration: '/seasons/toggleEnableRegistration',
    registerPlayerSeason: '/seasons/registerPlayerSeason',
    fetchplayers:'/seasons/fetchplayers',
    getdrafts:'/seasons/getdrafts',
    updatedrafts:'/seasons/updatedrafts',
    completedrafts:'/seasons/completedrafts',
    deleteRegisteredTeam: '/seasons/deleteRegisteredTeam',
    fetchOwnedTeams: 'player/teams/fetchOwnedTeams'
  },
  common: {
    matchLogsn: '/settings/logsn',
    getaccountsettings: '/settings/getaccountsettings',
    statFields: '/settings/stat-fields',
    viewteam: '/settings/view',
    UpdateLeagueFees: '/settings/leaguefees',
    getMatchesinListAll: '/matches/getMatchesinListall',
    getInfo: '/settings/getinfo',
    getFilters: '/settings/getfilters',
    leaguesList: '/settings/leagueslist',
    getMatchesinList: '/settings/getMatchesinList',
    updatenewspage: '/settings/update-newspage',
    getnewspage: '/settings/newspage',
    seasons: 'seasons',
    getschedules: '/settings/get-schedules',
    getteamslogo: '/settings/get-teams-logo',
    getmatchesdate: 'settings/getmatchesdate',
    getMatchdatesort: 'settings/getMatchdatesort',
    getcourts:'settings/courts',
    startMatchNew: 'settings/start-match',
    getNonPlayers:'users/getNonPlayers',
    updaterole:'users/updaterole',
    updateticket:'users/updateticket',
    getfiltersteams:'settings/getfiltersteams',
    registerteam:'settings/registerteam',
    registerteamseasonleague:'settings/registerteamseasonleague',
    fetchPreviousLeaguePlayer:'settings/previous-league-player/teamid',
    getregisteredteamsnew:'settings/getregisteredteamsnew',
    getregisteredteamsdraft:'settings/getregisteredteamsdraft',
    getplayerregistrations:'settings/getplayerregistrations',
    getLeadersDraft:'settings/getLeadersDraft',
    getFiltersaddteam:'settings/getFiltersaddteam',
    deleteplayerregistry:'settings/deleteplayerregistry',
    updateplayerregistry:'settings/updateplayerregistry',
  },
  website: {
    getWebsiteInfo: '/settings/getwebsiteinfo',
    updateteamlogo: '/settings/updateteamlogo',
    updateteamfev: '/settings/updateteamfev',
    postaccountsettings: '/settings/postaccountsettings',
    getaccountsettings: '/settings/getaccountsettings',
    saveWebsiteInfo: '/settings/savewebsiteinfo',
    updwebsiteinfo: '/settings/updwebsiteinfo',
    updateWebsiteInfo: '/settings/updatewebsiteinfo',
    deleteWebsiteInfo: '/settings/deletewebsiteinfo',
    savePromotionImage: '/settings/saveimage',
  },
  marketing: {
    sendEmailCampaign: '/marketing/create-campaign',
    list: '/marketing/getlist',
    deletecampaign: '/marketing/delete',
    deletedraftcampaign: '/marketing/deletedraft',
  }
}

export default ApiConfig;